import * as React from 'react'
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import '@fontsource/train-one'

const NotFound = () => (
  <Container>
    <Flex direction={{ base: 'column', lg: 'row' }} h='full'>
      <Flex flexDirection='column' mt={{ base: 12, lg: '60' }} mr='32'>
        <Heading as='h1' fontWeight='700' color='black' mb='4'>
          Ooops...
        </Heading>
        <Text fontSize='4xl' color='gray.600' mb='12'>
          Page Not Found
        </Text>
        <Box width='333px'>
          <Text fontSize='sm' color='gray.700' noOfLines={2}>
            Sorry. the content you’re looking for doesn’t exist. Either it was
            removed, or you mistyped the link.
          </Text>
        </Box>
      </Flex>
      <Flex
        width={{ base: 52, lg: '574px' }}
        height={{ base: 52, lg: '574px' }}
        mt='32'
      >
        <StaticImage src='../images/notFound.png' alt='' placeholder='none' />
      </Flex>
      <Flex flexDirection='column' mt='24'>
        <Text
          fontFamily='Train One, Fallback Outline'
          fontSize='12.5rem'
          color='teal.300'
          lineHeight='219px'
        >
          4
        </Text>
        <Text
          fontFamily='Train One, Fallback Outline'
          fontSize='12.5rem'
          color='teal.300'
          lineHeight='219px'
        >
          0
        </Text>
        <Text
          fontFamily='Train One, Fallback Outline'
          fontSize='12.5rem'
          color='teal.300'
          lineHeight='219px'
        >
          4
        </Text>
      </Flex>
    </Flex>
  </Container>
)

export default NotFound
